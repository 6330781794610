var loginRestoeModal = document.querySelector(".restoreAccess__modal");
var loginSection = document.querySelector(".login__wrap");
var restorePassBTN = document.querySelector(".login__form_btn__forModal span");
var canselRestoreModal = document.querySelector(
  ".restore__form .login__form_btn span"
);

restorePassBTN.addEventListener("click", function () {
  loginRestoeModal.classList.add("restoreAccess__modal__open");
  loginSection.classList.add("login__close");
});

canselRestoreModal.addEventListener("click", function () {
  loginRestoeModal.classList.remove("restoreAccess__modal__open");
  loginSection.classList.remove("login__close");
});
